@import './mixins.scss';
@import './variables.scss';

.Container {
  border-top: 1px solid $color-light;
  overflow: hidden;
  &:nth-child(odd) {
    border-right: 1px solid $color-light;
    @include breakpoint(small) {
      border-right: none;
    }
  }
  &:nth-child(2) {
    & > div > div > div > div > div:first-child {
      @include breakpoint(medium) {
        padding-bottom: calc(68% - 1px);
      }
    }
  }
  &:nth-child(6) {
    & > div > div > div > div > div:first-child {
      @include breakpoint(medium) {
        padding-bottom: calc(68% - 1px);
      }
    }
  }
  &:nth-child(10) {
    & > div > div > div > div > div:first-child {
      @include breakpoint(medium) {
        padding-bottom: calc(68% - 1px);
      }
    }
  }
  // &:nth-child(even) {
  //   & > div:first-child {
  //     padding-bottom: calc(68% - 1px);
  //   }
  // }

}

.Title {
  margin: 0;
  border-top: 1px solid $color-light;
  border-bottom: 1px solid $color-light;
  padding: 0.75rem 0.25rem;
  font-size: $text-sm;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
}

.Content {
  padding: 0.75rem 0.25rem;
  font-size: $text-sm;
  font-weight: 400;
  text-align: left;
}