@import './variables.scss';
@import './mixins.scss';

.Container {
  // position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  min-height: 10rem;
}

.CenteredContainer {
  transform: translateY(0);
  min-height: 15rem;
  @include breakpoint(medium) {
    width: calc(100% - 12.2rem);
    padding-left: 3rem;
  }
}

.HeroContainer {
  z-index: -1;
  position: absolute;
  left: unset;
  transform: translateY(-50%);
  min-height: 15rem;
  @include breakpoint(medium) {
    width: calc(100% - 12.2rem);
    padding-left: 3rem;
  }
}

.Inner {
  color: $color-light;
  background: $color-light;
  -webkit-animation: load 1s infinite ease-in-out;
  animation: load 1s infinite ease-in-out;
  width: 1px;
  height: 4em;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  &:before, &:after {
    position: absolute;
    top: 0;
    content: '';
    background: $color-light;
    -webkit-animation: load 1s infinite ease-in-out;
    animation: load 1s infinite ease-in-out;
    width: 1px;
    height: 4em;
  }
  &:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  &:after {
    left: 1.5em;
  }
}

@-webkit-keyframes load {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
