@import './mixins.scss';
@import './variables.scss';

.Body {
  display: block;
  border-top: 1px solid $color-light;
  &:first-child {
    border-top: none;
  }
  @include breakpoint(small) {
    flex-direction: column;
    
  }
  & > div:first-child {
    @include breakpoint(small) {
      width: 100%;
    }
    @include breakpoint(medium) {
      width: 25%;
    }
    @include breakpoint(large) {
      width: 13%;
    }
  }
  & > div:last-child {
    @include breakpoint(small) {
      width: 100%;
    }
    @include breakpoint(medium) {
      width: 75%;
    }
    @include breakpoint(large) {
      width: 87%;
    }
  }
}

.InnerContainer {
  position: relative;
  // display: flex;
  // flex-direction: column;
  display: inline-block;
  vertical-align: top;
}

.Name {
  border-bottom: 1px solid $color-light;
  padding: 0.5rem 0.5rem;
  font-size: $text-sm;
  font-weight: 400;
  color: $color-light;
  text-align: left;
  text-transform: uppercase;
  @include breakpoint(small) {
    border-top: 1px solid $color-light;
  }
}

.Content {
  overflow-y: hidden;
  padding: 0.25rem 0.5rem;
  font-size: $text-sm;
  font-weight: 400;
  color: $color-light;
  text-align: left;
  @include breakpoint(small) {
    max-height: unset!important;
  }
  & p + p {
    margin-top: 1em;
  }
}

.Button {
  cursor: pointer;
  width: 100%;
  height: calc(0.5rem + 1px);
  position: absolute;
  bottom: 0;
  background: $color-dark;
  @include breakpoint(small) {
    display: none;
  }
}

.ButtonIcon {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 1.375rem;
  height: calc(0.5rem + 1px);
  &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: -1px;
    left: 50%;
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    transform: translateX(-50%) rotate(45deg);
    border-bottom: 2px solid;
    border-right: 2px solid;
  }
}

.FlippedButtonIcon {
  &::after {
    top: 0;
    transform: translateX(-50%) rotate(-135deg);
  }
}