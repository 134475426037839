@import './variables.scss';

.NavigationItem {
  position: relative;
  height: 100%;
  display: flex;
  align-items: stretch;
  padding: 0 0.5rem 0 0.5rem;
  border-left: 1px solid $color-light;
  text-align: left;
}

.Title {
  position: relative;
  bottom: 1rem;
  margin: 0;
  font-size: $text-lg;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
}

.Link {
  display: flex;
  text-decoration: none;
}