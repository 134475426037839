@mixin breakpoint($class) {
  @if $class == small {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == medium {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == large {
    @media (min-width: 1024px) { @content; }
  }

  @else if $class == wide {
    @media (min-width: 1440px) { @content; }
  }

  @else if $class == ultra {
    @media (min-width: 1600px) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: small, medium, large, wide";
  }
}

@mixin browser($name) {
  @if $name == safari {

    // /* Safari 11+ */
    @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      @content;
    }}

    // /* Safari 10.1 */
    // @media not all and (min-resolution:.001dpcm){ 
    // @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
    //   @content;
    // }}

    /* Safari 6.1-10.0 (but not 10.1) */
        // @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0){ 
        //   @content;
        // }}

  }
}
