// Typography
$text-sm: 1rem; //56
// $text-md: 1.25rem;
$text-md: 1.5rem; //83
$text-lg: 1.75rem; //98
// $text-xl: 2.5rem;

// Colors
$color-light: #ffffff;
$color-dark: #b1b1b1;
