@import './mixins.scss';
@import './variables.scss';

.Container {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  padding: 0 0.5rem 0 0.5rem;
  border-left: 1px solid $color-light;
  margin: 0;
  text-transform: uppercase;
  cursor: pointer;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  @include browser(safari) {
    padding: 0 0 0 0.5rem;
  }
  @include breakpoint(small) {
    // padding: 0 0 0 0.5rem;
    width: 3rem;
    padding: unset;
    align-items: center;
  }
}

.Text {
  position: relative;
  bottom: 1rem;
  margin: 0;
  font-size: $text-lg;
  font-weight: 400;
}