.Container {
  position: relative;
  height: 0;
  padding-bottom: 68%;
}

.Inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}