@import './mixins.scss';
@import './variables.scss';

.Container {
  @include breakpoint(small) {
    &:last-of-type {
      & .Content {
        border-bottom: none;
      }
      & .TextContainer {
        border-bottom: none;
      }
    }
  }
  @include breakpoint(medium) {
    min-height: 100%;
    align-self: stretch;
    cursor: pointer;
    &:hover {
      .NumberContainer {
        opacity: 0.55;
      }
    }
    &:first-of-type {
      .NumberContainer {
        border-left: unset;
      }
    }
    &:last-of-type {
      & .NumberContainer {
        border-right: none;
      }
      .OpenedNumberContainer {
        border-right: 1px solid $color-light;
      }
    }
  }
}

.Title {
  margin-top: auto;
  font-weight: 400;
  text-transform: uppercase;
  text-align: left;
  cursor: pointer;
  @include breakpoint(small) {
    font-size: $text-md;
    padding: 0.5rem;
    border-top: 1px solid $color-light;
    border-bottom: 1px solid $color-light;
  }
  @include breakpoint(medium) {
    font-size: $text-md;
  }
}

.Inner {
  display: flex;
  // align-items: flex-end;
  height: 100%;
  @include breakpoint(small) {
    flex-direction: column;
  }
}

.Input {
  display: none;
  &:checked + .TextContainer {
    color: red;
    max-width: unset;
    
  }
}

.TextContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include breakpoint(small) {
    max-height: 0;
    flex-direction: column-reverse;
    border-bottom: 1px solid $color-light;
  }
  @include breakpoint(medium) {
    max-width: 0;
    padding: 0.5rem;
  }
}

.OpenedTextContainer {
  max-width: unset;
  max-height: unset;
  flex-basis: 50%;
}

.fullWidthTextContainer {
  flex-basis: unset;
}

.OpenedNumberContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include breakpoint(medium) {
    min-width: 3rem;
    border-right: 1px solid $color-light;
  }
}

.ContentAndImageContainer {
  display: flex;
  overflow: hidden;
  @include breakpoint(small) {
    flex-direction: column;
  }
  @include breakpoint(medium) {
    max-width: 0;
  }
}


.OpenedContentAndImageContainer {
  max-width: unset;
  @include breakpoint(small) {
    max-height: unset;
    border-bottom: none;
  }
  @include breakpoint(medium) {
    max-height: unset;
  }
}

.Content {
  text-align: left;
  @include breakpoint(small) {
    display: block;
    padding: 0.5rem;
    font-size: $text-sm;
  }
  @include breakpoint(medium) {
    display: none;
    font-size: $text-md;
  }
}

.OpenedContent {
  @include breakpoint(medium) {
    display: block;
    margin-bottom: 8rem;
  }
}

.NumberContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 1px solid $color-light;
  padding: 0.5rem;
  @include breakpoint(small) {
    text-align: left;
    border-left: none;
  }
}

.Number {
  @include breakpoint(small) {
    font-size: $text-lg;
  }
  @include breakpoint(medium) {
    font-size: $text-md;
  }
}

.ImageContainer {
  @include breakpoint(small) {
    flex-basis: 0%;
    & div {
      height: 0;
    }
  }
  @include breakpoint(medium) {
    flex-basis: 50%;
  }
}

.OpenedImageContainer {
    & div {
      height: 100%;
    }
}

.VerticalTitle {
  @include breakpoint(small) {
    display: none;
  }
  @include breakpoint(medium) {
    display: flex;
    align-items: center;
    margin: 0;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    font-size: $text-sm;
  }
}