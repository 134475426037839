.Hero {
  z-index: 1;
  // position: absolute;
  height: 100%;
  width: 100%;
  // background-image: url('../images/hero.jpg');
  // background-position: center;
  // background-size: cover;
}

.VideoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
}

.SubSwiper {
  position: absolute!important;
  top: 0;
  width: 100%;
  height: 100%;
}

.Slide {
  position: relative;
  width: 100vw!important;
  height: 100vh!important;
}

.SubSlide {
  
}

.ImageContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.Image {
  z-index: 1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}