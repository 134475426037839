@import './variables.scss';

.Footer {
  width: 100%;
  text-align: left;
}

.Item {
  display: block;
  padding: 0.25rem 0.5rem;
  border-bottom: 1px solid $color-light;
}

.Title {
  font-size: $text-lg;
  font-weight: 400;
  text-transform: uppercase;
}

.Address {
  font-size: $text-lg;
  text-decoration: none;
}