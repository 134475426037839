@import './mixins.scss';
@import './variables.scss';

.Container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.List {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  flex: 1;
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
  border-top: 1px solid $color-light;
  @include breakpoint(small) {
    flex-direction: column;
  }
  @include breakpoint(medium) {
  }
}

.Title {
  margin: 0;
  padding: 0.25rem 0.5rem;
  font-size: $text-md;
  font-weight: 400;
  color: $color-light;
  text-align: left;
  text-transform: uppercase;
  border-top: 1px solid $color-light;
}