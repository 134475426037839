@import './mixins.scss';
@import './variables.scss';

.Container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  list-style: none;
  margin: 0;
  padding: 0;
  @include breakpoint(small) {
    grid-template-columns: 1fr;
    grid-auto-rows: unset;
  }
}

.Title {
  margin: 0;
  padding: 0.25rem 0.5rem;
  font-size: $text-md;
  font-weight: 400;
  color: $color-light;
  text-align: left;
  text-transform: uppercase;
  border-top: 1px solid $color-light;
}