@import './mixins.scss';
@import './variables.scss';
.Container {
  position: absolute;
  top: 0;
  display: flex;
  height: 100%;
  z-index: 1;
  @include breakpoint(small) {
    width: calc(100% - 3.5rem);
  }
  @include breakpoint(medium) {
    width: calc(100% - 16rem);
    padding-left: 3rem;
  }
}

.IdContainer {
  min-height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 0 0.5rem;
  border-right: 1px solid $color-light;
  @include breakpoint(small) {
    border-right: unset;
  }
}

.Id {
  color: transparent;
  padding-bottom: 0.5rem;
  font-size: $text-lg;
}

.Inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Description {
  padding: 0.5rem;
  text-align: left;
  font-size: $text-sm;
  text-transform: uppercase;
}

.Title {
  display: block;
  width: 100%;
  margin: auto 0 0.5rem 0.5rem;
  font-size: $text-lg;
  font-weight: 400;
  text-transform: uppercase;
  text-align: left;
}

.TitleVisible {
  // animation: drop-in;
  // animation-duration: 1000ms;
  // animation-fill-mode: forwards;
  // transition: transform cubic-bezier(0.22, 1, 0.36, 1);
}

@keyframes drop-in {
  from {
    opacity: 0;
    transform: translateY(-150%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}