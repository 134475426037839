@import './mixins.scss';
@import './variables.scss';

.Container {
  padding: 0.25rem 0.5rem;
  text-align: left;
  text-transform: uppercase;
  border-top: 1px solid $color-light;
}

.Text {
  margin: 0;
  font-size: $text-md;
  font-weight: 400;
  color: $color-light;
}