@import './mixins.scss';
@import './variables.scss';

.Container {
  display: block;
}

.SquareContainer {
  position: relative;
  object-fit: cover;
  display: inline-block;
  height: 0;
  vertical-align: top;
  filter: grayscale(1);
  @include breakpoint(small) {
    padding-bottom: 100%;
  }
  @include breakpoint(medium) {
    padding-bottom: 25%;
  }
  @include breakpoint(large) {
    padding-bottom: 13%;
  }
}

.FittedContainer {
  position: relative;
  object-fit: cover;
  display: block;
  height: 0;
  vertical-align: top;
  padding-bottom: 68%;
}

.heroContainer {
  width: 100%;
  height: 100%;
}

.StandingContainer {
  height: 100%;
  width: 100%;
}

.Media {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  height: 100%;
}

.FittedImage {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.HeroImage {
  object-fit: cover;
  object-position: center;
}

.AnimatedImage {
  animation: zoomtruck;
  animation-duration: 20s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite
}

.StandingImage {
  object-fit: cover;
  object-position: center;
}

@keyframes zoomtruck {
  // from {
  //   transform: scale(1) translateX(0%);
  // }
  // to {
  //   transform: scale(1.2) translateX(4%);
  // }
  0% {
    transform: scale(1) translateX(0%);
  }
  50% {
    transform: scale(1.1) translateX(3%);
  }
  100% {
    transform: scale(1.2) translateX(4%);
  }
}