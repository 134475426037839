@import './variables.scss';

.Body {
  display: block;
  width: 100%;
  border-bottom: 1px solid $color-light;
}

.Title {
  padding: 0.25rem 0.5rem;
  font-size: $text-md;
  font-weight: 400;
  color: $color-light;
  text-align: left;
  text-transform: uppercase;
}

.List {
  display: flex;
  flex-direction: column;
  margin: 0;
}