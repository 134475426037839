@import './mixins.scss';

.Container {
  // display: flex;
  height: 100%;
  width: calc(100% - 12.2rem - 1px);
  padding-left: 3rem;
  @include breakpoint(small) {
    width: calc(100% - 3rem);
  }
  @include breakpoint(medium) {
    width: calc(100% - 12.2rem);
  }
}